<template>
  <div>
    <v-breadcrumbs :items="[
      { text: 'Solutions', exact: true, to: { name: 'solutions' } },
      { text: 'Corporate Challenges', disabled: true },
    ]" />
    <div :class="`my-10 ${$vuetify.breakpoint.xs?'':''}`">
      <h1>Engaging and Connected Corporate Challenges</h1>
      <p>
        We have created a special version of our platform dedicated to corporate fitness challenges: <a href="https://corporatefitness.app">CorporateFitness.app</a>
      </p>
    </div>

    <div class="pb-6 pb-md-12">
      <v-row >
        <v-col cols="12" md="6">
          <v-card :color="$helpers.getGradientColor(0)">
            <v-card-text class="pb-0"><v-icon size="100">fadl fa fa-watch-fitness</v-icon></v-card-text>
            <v-card-title class="headline">All major fitness trackers supported</v-card-title>
            <v-card-text>
              Allow your employees to use the app or device they already love! 
              We support {{ providers.getSupportedProviders().join(', ') }}, plus Apple Watch and Samsung Health.
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="6">
          <v-card :color="$helpers.getGradientColor(1)">
            <v-card-text class="pb-0"><v-icon size="100">fadl fa fa-users</v-icon></v-card-text>
            <v-card-title class="headline">Build and Strengthen Connections</v-card-title>
            <v-card-text>
              Build and strengthen inter-team connections within your organization. Host team challenges to promote your divisions, facilities or country departments to work together towards a common goal.
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="6">
          <v-card :color="$helpers.getGradientColor(2)">
            <v-card-text class="pb-0"><v-icon size="100">fadl fa fa-medal</v-icon></v-card-text>
            <v-card-title class="headline">Everyone wins!</v-card-title>
            <v-card-text>
              We offer various challenge types to promote physical and mental wellbeing within teams and organizations.
              Award Badges to keep your employees motivated and active throughout the entire challenge!
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="6">
          <v-card :color="$helpers.getGradientColor(3)">
            <v-card-text class="pb-0"><v-icon size="100">fadl fa fa-shield-check</v-icon></v-card-text>
            <v-card-title class="headline">Private and secure</v-card-title>
            <v-card-text>
              We understand that health and fitness data of employees needs to be carefully protected. We have various options to configure which devices and options you want to offer to your employees.
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" class="text-muted text-center">
          <div><v-icon class="my-6" color="grey">fal fa-chevron-double-down</v-icon></div>
          Scroll down for more features.
        </v-col>
      </v-row>
    </div>


    <div id="page-customers" class="mt-0 white--text">
      <div class="container pb-0">
        <v-row>
          <v-col class="pb-4">
            <h1 class="headline">Trusted by world-famous brands</h1>
            <p>
              The Sodisp Platform is used by world-renowned organizations all across the world. Some of our key partners are:
            </p>
            <SectionCustomers :showcase="false" corporate/>

          </v-col>
        </v-row>
      </div>
    </div>
    <div class="grey lighten-4">
    <v-container class="pb-0">
      <v-row>
        <v-col class="pb-0">
          <h3 class="title">Integration with all major fitness trackers</h3>
          <SectionIntegrations />
        </v-col>
      </v-row>
    </v-container>
    </div>

    <div class="green white--text py-6 py-md-12">
      <div class="container text-center">
        <v-row>
          <v-col cols="12" md="12">
            <h2 class="subtitle">Visit CorporateFitness.app</h2>
            <h1 class="subtitle">Create a connected corporate challenges and go live in minutes!</h1>
            <p class="mb-0 mt-4">
              <v-btn class="mb-4" color="white" href="https://corporatefitness.app">Get Started on CorporateFitness.app</v-btn>
            </p>
          </v-col>
        </v-row>
      </div>
    </div>

  </div>
</template>


<script>
import SectionIntegrations from "@/components/SectionIntegrations.vue";
import SectionCustomers from "@/components/SectionCustomers.vue";
import siteData from '@/data/site.json'
import providers from '@/util/oauthProviders.js'

export default {
  name: "Contact",
  components: {
    SectionIntegrations,
    SectionCustomers,
  },
  props: {
  },
  data: function() {
    return {
      siteData: siteData,
      providers: providers,
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
  watch: {
  }
};
</script>

<style lang="scss" scoped>
  .indent { margin: 0 20%; }
  .features {
    i { background-color: #F4652422; border-radius: 50%; padding: 20px; margin-top: 20px; }
    .plus {
      margin: 0 20px; line-height: 40px;
      color: #F46524;
    }
    h4 { margin-top: 8px; }
  }
</style>